// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "eu-west-2",
  aws_cognito_identity_pool_id:
    "eu-west-2:ed681c6f-58e8-473b-af1d-2a4ffb1b130f",
  aws_cognito_region: "eu-west-2",
  aws_user_pools_id: "eu-west-2_IeZR0eqox",
  aws_user_pools_web_client_id: "24jmae0ep09j40ho5m604st9od",
  oauth: {},
  aws_appsync_graphqlEndpoint:
    "https://fvwjuxo5x5a37gfhtqaftm5v6u.appsync-api.eu-west-2.amazonaws.com/graphql",
  aws_appsync_region: "eu-west-2",
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: "da2-un3lmud7pjge7huxa6qp2j3kz4",
}

export default awsmobile
