/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import Amplify, { Auth } from "aws-amplify"
import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync"
import awsconfig from "./src/aws-exports"

Amplify.configure(awsconfig)

export const apiKeyClient =
  typeof window !== "undefined" && window.document
    ? new AWSAppSyncClient({
        url: awsconfig.aws_appsync_graphqlEndpoint,
        region: awsconfig.aws_appsync_region,
        auth: {
          type: AUTH_TYPE.API_KEY,
          apiKey: awsconfig.aws_appsync_apiKey,
        },
        disableOffline: true,
      })
    : null

export const userPoolsClient =
  typeof window !== "undefined" && window.document
    ? new AWSAppSyncClient({
        url: awsconfig.aws_appsync_graphqlEndpoint,
        region: awsconfig.aws_appsync_region,
        auth: {
          type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
          jwtToken: async () =>
            (await Auth.currentSession()).getIdToken().getJwtToken(),
         
        },
        disableOffline: true,
      })
    : null

export const registerServiceWorker = () => true

export const onServiceWorkerUpdateReady = () => {
  window.location.reload()
}
